import React from 'react';
import './App.css';
import TYPES from './data/types.json';

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NavigationComponent from './components/navigation.component';
import Home from './components/home.component';
import Type from './components/type.component';
import Challenge from './components/challenge.component';


const ICON_PATH = "/assets/images/icons/Icon_";

export default function App() {
  return (
    <Router>
      <>
      <NavigationComponent /> 
        <Route render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={600}
            >
              <Switch>
                <Route path="/challenge">
                  <Challenge  ICON_PATH={ ICON_PATH } TYPES={ TYPES } />
                </Route>
                <Route path={`/types/:typeId`}>
                  <div className="type__wrapper">
                    <Type ICON_PATH={ ICON_PATH } TYPES={ TYPES } />
                  </div>
                </Route>
                <Route path="/">
                  <Home ICON_PATH={ ICON_PATH } TYPES={ TYPES } />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
        />
      </>
    </Router>
  );
}