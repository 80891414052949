import React from 'react';
import { Link } from "react-router-dom";

export default function Home(props) {
    let typeLinks = props.TYPES.map(type => <li className={type.name.toLowerCase()} key={type.id}><Link to={"/types/" + type.id}><img src={props.ICON_PATH + type.name + ".png"} alt={type.name} title={type.name} />{type.name}</Link></li>);
  
    return (
      <div className="home">
        <div className="home__header">
          <h1>Pokè<wbr/>school</h1>
        </div>
        <ul>
          {typeLinks}
        </ul>
        {/*<Link to="/challenge">Challenge</Link>*/}
      </div>
    );
  }