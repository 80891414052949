import React from 'react';

export default class Challenge extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iconPath : props.ICON_PATH,
            types : props.TYPES,
            timer: 5,
            challangeStarted: false,
            gameOver: false,
            loop: null,
            lastLoopUpdate: null,
            currentType: null,
            typeCache: []
        }

        this.handleStartClick = this.handleStartClick.bind(this);
        this.startChallenge = this.startChallenge.bind(this);
        this.challengeLoop = this.challengeLoop.bind(this);
    }

    startChallenge() {
        this.setState({challangeStarted:true,loop: requestAnimationFrame(this.challengeLoop)});
    }

    pauseChallenge() {

    }

    stopChallenge() {
        cancelAnimationFrame(this.state.loop);
        this.setState({challangeStarted: false});
    }

    challengeLoop() {
        let newtimer = (this.state.timer -1);
        let lastUpdate = this.state.lastLoopUpdate;
        let now = Date.now();

        if(!lastUpdate || now - lastUpdate >= 1000) {
            this.setState({timer: newtimer, lastLoopUpdate: now});
        }

        if( this.state.timer > 0 ) {
            requestAnimationFrame(this.challengeLoop);
        } else {
            this.stopChallenge();
        }
    }

    getRandomType() {
        let types = this.state.types;
        let currentType = this.state.types[this.getRandomInt(0, (types.length - 1) )];
        this.setState({currentType: currentType}); 
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    handleStartClick() {
        this.startChallenge();
    }

    render() {
        //let TYPES = this.state.types;
        //let ICON_PATH = this.state.iconPath;
        let currentType = this.state.currentType;

        if( this.state.gameOver ) {

        }else {
            return(
                <>
                    <h2>Challenge</h2>
                    {currentType !== null ? <h3>{currentType.name}</h3> : ''}
                    {this.state.challangeStarted ? <p>{this.state.timer}</p> : ''}
                    {this.state.challangeStarted ? '' : <button onClick={this.handleStartClick}>Start</button>}
                    <button onClick={this.getRandomType.bind(this)}>Type</button>
                </>
            )
        }
    }
}