import React from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";

function Navigation(props) {
    const { location } = props;
    let homeLink = location.pathname !== "/" ? <li className="top-navigation__home" key="nav-item-home"><Link to="/" title="Home" aria-label="Back to Home"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></Link></li> : '';

    return (
        <nav className="top-navigation">
            <ul>
                {homeLink}
            </ul>
        </nav>
    );
}

const NavigationComponent = withRouter(Navigation);

export default NavigationComponent;