import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";

function getTypeList(array = [], headline, types, iconPath) {
    var i = 0;
    if (array.length > 0) {
        return <>
            <h3>{headline}</h3>
            <ul key={"ul" + headline + "_" + i}>
                {array.map((id, index) => {
                    i++;
                    return <li key={id + i + index}>{types.map(type => {
                        if (type.id === id) {
                            i++;
                        return <Link className={'type '+type.name.toLowerCase()} key={type.id + type.name + i} to={`/types/${type.id}`} title={type.name}><img src={iconPath + type.name + ".png"} alt="" title="" />{type.name}</Link>;
                        }
                        return '';
                    })}</li>
                })}
            </ul>
        </>;
    }
}

export default function Type(props) {
    let { typeId } = useParams();
    let type = props.TYPES.filter(type => type.id === parseInt(typeId))[0];
    const [selectedOption, changeOption] = useState("attack");

    function handleChange(e) {
        changeOption(e.target.value);
    }

    return (
        <div className={"type type--" + type.name.toLowerCase()}>
            <div className="type__header">
                <div className="wrapper">
                    <img src={props.ICON_PATH + type.name + ".png"} alt="" title="" />
                    <h2 className="type__name">{type.name}</h2>
                </div>
            </div>
            <div className="type__effectness">
                <input type="radio"
                    name="effect-table"
                    id="attack-panel"
                    value="attack"
                    onChange={handleChange}
                    checked={selectedOption === "attack"} />
                <label htmlFor="attack-panel">Attack</label>
                <div className="panel">
                    {getTypeList(type.attacking.strongAgainst, "Super effective", props.TYPES, props.ICON_PATH)}
                    {getTypeList(type.attacking.weakAgainst, "Not very effective", props.TYPES, props.ICON_PATH)}
                    {getTypeList(type.attacking.noEffect, "Not effective", props.TYPES, props.ICON_PATH)}
                </div>
                <input type="radio"
                    name="effect-table"
                    id="defense-panel"
                    value="defense"
                    onChange={handleChange}
                    checked={selectedOption === "defense"} />
                <span className="panel-viewer"></span>
                <label htmlFor="defense-panel">Defense</label>
                <div className="panel">
                    {getTypeList(type.defending.resistTo, "Not very effective", props.TYPES, props.ICON_PATH)}
                    {getTypeList(type.defending.vulnerableTo, "Super effective", props.TYPES, props.ICON_PATH)}
                </div>
            </div>
        </div>
    );
}